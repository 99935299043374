//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-504:_7220,_4508,_9588,_7592,_1688,_4516,_4091,_708;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-504')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-504', "_7220,_4508,_9588,_7592,_1688,_4516,_4091,_708");
        }
      }catch (ex) {
        console.error(ex);
      }