const baseFitmentFields = ['Year', 'Make', 'Model'];
const extraFitmentFields = [];
const ignoreFields = ['Universal'];

const searchPageUrl = '/Search_ep_42-1.html';
const isOnSearchPage = window.location.pathname === searchPageUrl;

const onVerifyFitmentRequestReceived = (response) => {
  const compatibleOptionIds = response.Items?.[0]?.CompatibleOptionIds;
  const optionSelects = [...window.document.querySelectorAll('.opt-regular .form-control')];

  if (compatibleOptionIds?.length) {
    const selectWithCompatibleOptions = optionSelects.find((select) =>
      [...select.options].some((option) => compatibleOptionIds.includes(option.value)),
    );

    if (selectWithCompatibleOptions) {
      let firstCompatibleOptionIndex = null;

      [...selectWithCompatibleOptions.options].forEach((option, i) => {
        if (!compatibleOptionIds.includes(option.value)) {
          option.style.display = 'none';
        } else if (firstCompatibleOptionIndex === null) {
          firstCompatibleOptionIndex = i;
        }
      });

      selectWithCompatibleOptions.selectedIndex = firstCompatibleOptionIndex;
      selectWithCompatibleOptions.dispatchEvent(new Event('change'));
    } else {
      unhideSelectOptions(optionSelects);
    }
  } else {
    unhideSelectOptions(optionSelects);
  }
};

function unhideSelectOptions(selects) {
  selects.forEach((select) =>
    [...select.options].forEach((option) => {
      option.style.display = 'block';
    }),
  );
}

export default {
  platform: 'shift4shop',
  searchPageUrl,
  SearchRequestDefaults: {
    pageSize: 36,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    onVerifyFitmentInitRequestReceived: onVerifyFitmentRequestReceived,
    onVerifyFitmentRequestReceived,
  },
  facets: {
    rangedFacet: { fields: ['price'], name: 'priceFacet' },
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Name': 'A to Z',
        'Name:desc': 'Z to A',
        'Price': 'Price: Ascending',
        'Price:desc': 'Price: Descending',
      },
    },
    {
      name: 'FacetPanel',
      ignoreFields,
    },
    { name: 'SearchResult' },
    {
      name: 'SearchBox',
      location: { replace: '#FRAME_SEARCH form', class: 'cm_search-box-root_container' },
      template: 'SearchBox',
      visibleIf: () => isOnSearchPage,
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.site-header [data-target="#searchModal"]',
        wrapper: 'a',
        class: 'pull-right',
      },
      template: 'searchBox/dialogButtonMobile',
      visibleIf: () => isOnSearchPage,
    },
    // {
    //   name: 'VerifyFitment',
    //   type: 'VehicleWidget',
    //   location: { insertAfter: '.extrafieldsBlock' },
    //   template: 'fitmentSearch/verifyFitment',
    // },
    {
      name: 'FitmentTableTab',
      type: 'FitmentTable',
      location: 'body.listing-page #rTabs a[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTableTabMobile',
      type: 'FitmentTable',
      location: 'body.listing-page #rTabs .r-tabs-accordion-title a[href="#tab-fitment"]',
      template: 'fitmentSearch/fitmentTableTab',
    },
    {
      name: 'FitmentTable',
      location: 'body.listing-page #rTabs #tab-fitment',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
